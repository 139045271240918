<template lang="pug">
.sign-in
  h1 Acesse sua conta

  form(@submit.prevent="signIn")
    TextInput(
      label="E-mail",
      type="email",
      v-model="state.email",
      @blur="v$.email.$touch",
      :errors="v$.email.$errors",
      autocomplete="email")
    TextInput(
      label="Senha",
      type="password",
      v-model="state.password",
      @blur="v$.password.$touch",
      :errors="v$.password.$errors",
      autocomplete="current-password")

    button(v-show="false", type="submit")

    .actions
      Button(label="Acessar conta", @click.prevent="signIn", :loading="loading", href="", size="large", rounded)

  .actions
    Button(label="Esqueci minha senha", type="text", to="/minha-conta/recuperar-senha")
    Button(label="Não tenho uma conta Magenta", type="text", :to="{ name: 'subscription-register' }")
</template>
<script setup lang="ts">
import { useAuth } from '~/store/auth'
import useVuelidate from '@vuelidate/core'
import { required, email } from '~/scripts/validators/validators'
import profileService from '~/services/profile/profileService';

const auth = useAuth()
const notifications = useNotifications()

const route = useRoute()
const router = useRouter()

definePageMeta({
  middleware: () => {
    const auth = useAuth()

    if (auth.isAuthenticated) {
      return navigateTo({ name: "dashboard" })
    }
  }
})

const registerProfile = useSessionStorage<string>("register-profile", null)

const loading = ref(false)

const state = reactive({
  email: "",
  password: ""
});

const rules = {
  email: {
    email,
    required
  },
  password: {
    required
  }
}

const v$ = useVuelidate(rules, state)

const signIn = async () => {
  if (await v$.value.$validate()) {
    loading.value = true

    try {
      const signInResponse = await auth.signIn(state.email, state.password)

      if (signInResponse) {
        updateProfile()

        const redirect = route.query["redirect"]

        if (redirect) {
          if (redirect)
            router.push(decodeURIComponent(redirect as string))
        } else {
          router.push({ name: "dashboard" })
        }
        notifications.success("Bem-vindo de volta!")
      } else {
        notifications.error("Não foi possível entrar em sua conta. E-mail e/ou senha inválidos.")
      }
    } catch (e: any) {
      if ("status" in e && e.status != 401) {
        captureException(new Error('Failed to sign in', { cause: e }))
      }

      notifications.error("Não foi possível entrar em sua conta. E-mail e/ou senha inválidos.")
    } finally {
      loading.value = false
    }
  }
}

const updateProfile = () => {
  try {
    if (registerProfile.value) {
      const profile = JSON.parse(registerProfile.value)

      profileService.updateSelfProfile(profile)

      registerProfile.value = null
    }
  } catch (e) {
    captureException(new Error('Failed to update profile after sign in', { cause: e }))
  }
}

onMounted(() => {
  if (route.query["email"]) {
    state.email = route.query["email"] as string
  }
})
</script>
<style lang="sass" scoped>
.sign-in
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .actions
    display: flex
    flex-direction: column
    margin: 32px 0
    align-items: center

    .large
      padding: 4px 32px
</style>
